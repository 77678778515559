import React from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import SliderTwo from "@/components/slider-two";
import FeatureOne from "@/components/feature-one";
import AboutOne from "@/components/about-one";
import ServiceOne from "@/components/service-one";
import ProductOne from "@/components/products/product-one";
import CtaOne from "@/components/cta-one";
import FunfactOne from "@/components/funfact-one";
import GalleryOne from "@/components/gallery-one";
import TestimonialsOne from "@/components/testimonials-one";
import SponsorOne from "@/components/sponsor-one";
import CtaTwo from "@/components/cta-two";
import BlogOne from "@/components/blog-one";
import ContactOne from "@/components/contact-one";
import { allClients } from "@/data";
const HomeOne = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Cooling Towers">
        <HeaderOne />
        <SliderTwo />
        <FeatureOne />
        <AboutOne />
        <ProductOne />
        <CtaOne />
        <FunfactOne />
        <GalleryOne />
        {/* <TestimonialsOne /> */}
        {/* <CtaTwo /> */}
        <SponsorOne title="Private Sector" logos={allClients.private} />
        <SponsorOne title="Public Sector" logos={allClients.public} />
        <SponsorOne title="Approved By" logos={allClients.approved} />

        {/* <BlogOne /> */}
        <ContactOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default HomeOne;
