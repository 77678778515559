import React from "react";
import Img from "@/components/img";
import { SRLWrapper } from "simple-react-lightbox";

const GalleryCard = ({ src, alt, title, url }) => {
  const options = {
    settings: {
      disableKeyboardControls: true,
      disablePanzoom: true,
      disableWheelControls: true,
    },
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    }
}
  return (
    <div className="gallery_width">
      <div className="gallery_fixed_height">
        <div className="gallery_width__image">
          <Img src={src} alt={alt} className="img-fluid" />
        </div>
        
        <SRLWrapper options={options}>
          <a
            href={src}
            className="gallery_icon_btn text-center"
            data-attribute="SRL"
            aria-label="open image"
          >
            <div className="gallery_overlay">
              {/* <h3>{title}</h3> */}
              <img src={src} className="img-fluid" alt={title} />
              {/* <i className="fa fa-angle-right"></i> */}
            </div>
          </a>
        </SRLWrapper>
      </div>
    </div>
  );
};

export default GalleryCard;
